import { ReactElement } from 'react';
import { IActivityWithAvailability } from '@/common/domain/Merge.domain';
import { ImageLabels } from './ImageLabels';
import { NewImagesGalleryComponent } from '@/shared/ImagesGallery/NewImagesGallery';
import * as S from './NewImagesGallery.styles';
import URL from '@/common/app/utils/urlsUtils';
import { TNewAssets } from '@/screens/ActivityPage/service/createAssets';
import { myImageLoader } from '@/lib/imageLoader';
import { defaultPlaceholder } from '@/shared/images/preloader/defaultPlaceholder';

type ImagesGalleryProps = {
    activity: IActivityWithAvailability;
    reviewsCount: number;
    disabled?: boolean;
    position: number;
    item_list_id: string;
    item_list_name: string;
    withPaddings?: boolean;
    event: boolean;
};

export const NewImagesGallery = ({
    activity,
    disabled,
    reviewsCount,
    position,
    withPaddings,
    event,
    item_list_name,
    item_list_id,
}: ImagesGalleryProps): ReactElement => {
    const imagesSrc: TNewAssets[] = [
        { type: 'activity', id: activity.id, placeholder: activity.placeholder || '' },
        ...((activity.images?.map(({ id, placeholder }) => ({
            type: 'photo',
            id,
            placeholder: placeholder || '',
        })) || []) as TNewAssets[]),
    ];

    const images =
        imagesSrc.length > 1
            ? [imagesSrc[imagesSrc.length - 1], ...imagesSrc, imagesSrc[0]]
            : imagesSrc;

    const imagesElement = () =>
        images.map(({ type, id, placeholder }, index) => {
            return (
                <S.Container key={index}>
                    <S.ImageTag
                        loader={myImageLoader}
                        src={`${URL.image}/${type}/${id}/center/1040x640/ts`}
                        width={520}
                        height={320}
                        blurDataURL={placeholder || defaultPlaceholder}
                        placeholder="blur"
                        objectFit="cover"
                        loading="lazy"
                    />
                </S.Container>
            );
        });

    return (
        <NewImagesGalleryComponent
            additionalElement={
                <ImageLabels
                    activity={activity}
                    reviewsCount={reviewsCount}
                    disabled={disabled}
                    position={position}
                    item_list_name={item_list_name}
                    item_list_id={item_list_id}
                />
            }
            items={images}
            itemsElement={imagesElement}
            withPaddings={withPaddings}
            event={event}
        />
    );
};
